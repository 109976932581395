<template>
  <div>
    <b-table striped hover sort-icon-right :fixed="isXs"
        class="mb-2 mb-sm-3"
        :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" no-local-sorting @sort-changed="sortChanged">
      <template #cell(uuid4)="data">
        <div v-if="btnShow">
          <b-dropdown v-if="isXs" text="" right>
            <b-dropdown-item :variant="btnVariant" :id="btnIdPrefix+items.indexOf(data.item)"
                @click.native="btnClick(data.item)" :to="btnTo">
              <slot name="buttons" />
            </b-dropdown-item>
          </b-dropdown>
          <b-button v-else type="button" :variant="btnVariant" :id="btnIdPrefix+items.indexOf(data.item)"
              @click="btnClick(data.item)" :to="btnTo">
            <slot name="buttons" />
          </b-button>
        </div>
      </template>
    </b-table>
    <b-row fluid>
      <b-col v-if="showPagination" class="d-flex align-items-end">
        <Pagination ref="pagi" @click="changePage" />
      </b-col>
      <b-col>
        <slot name="nextToPagi" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { REST } from '@/components/RestCall';
import Query from '@/components/RestQuery';
import Pagination from '@/components/Pagination';

export default {
  data() {
    return {
      items: [],
      sortBy: 'tableName',
      sortDesc: false,
      isLoading: false,
      localQuery: null,
    }
  },
  created() {
    this.localQuery = { ... this.query };
    // values would need to be moved to store to survive "F5"
    [this.sortDesc, this.sortBy, ] = Query.fetchSort(this.localQuery, this.sortDesc, this.sortBy);
    Query.setSort(this.localQuery, this.sortDesc, this.sortBy, 'uuid4');
    this.update();
  },
  computed: {
    fields() {
      return [
        { key: 'tableName', label: this.$t('tab.n'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: 100%;', sortable: true },
        { key: 'uuid4', label: this.isXs?'':this.$t('tab.a'), class: 'pl-2 pl-sm-3 pr-0', thStyle: 'width: 35px;' },
      ];
    },
    uiSett() {
      return this.$store.getters.settings;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
  },
  methods: {
    sortChanged(ctx) {
      if (ctx.sortBy == '') { return; }
      [this.sortDesc, this.sortBy, ] = Query.fetchSort(this.localQuery, ctx.sortDesc, ctx.sortBy);
      Query.setSort(this.localQuery, ctx.sortDesc, ctx.sortBy, 'uuid4');
      if (this.showPagination) {
        this.$refs.pagi.reset();
      }
      Query.setPagi(this.localQuery, null, this.uiSett.visiblePerPage);
      this.update();
    },
    changePage(nextString) {
      Query.setPagi(this.localQuery, nextString, this.uiSett.visiblePerPage);
      this.update();
    },
    update(isDelete = false) {
      this.isLoading = true;
      if (isDelete === true && this.items.length === 1) { // last element in current page: get previous page
        const newNext = this.$refs.pagi.getNextFromPrev(2); // prev-prev entry fetches previous page
        if (newNext === null) {
          this.items = []; // there is nothing, just return
          return;
        } else {
          Query.setPagi(this.localQuery, newNext, this.uiSett.visiblePerPage);
          this.$refs.pagi.movePage(-1);
        }
      }
      REST.get('/customers/' + this.$store.getters.uuid + '/tables', this.getFormattedQuery()
          ).then(resp => {
            this.items = resp.d.data; // before isLoading = false to avoid flickering
            this.isLoading = false; // needed for pagination update (ref not available if v-if=false)
            if (this.showPagination) {
              this.$nextTick(() => { // refs not yet existing (rendered) without nextTick
                this.$refs.pagi.update(resp.d.links.next);
                this.$emit('loaded', { hasContent: true, hasMore: this.$refs.pagi.hasMore() });
              });
            }
          }).catch(e => {
            // catch 404 warning: cust may not have added tables yet
            if (e.code != 404) {
              this.$store.commit('showWarn',e.message);
            }
            this.items = [];
            this.$emit('loaded', { hasContent: false, hasMore: false });
          }).finally(() => {
            this.isLoading = false;
          })
    },
    getFormattedQuery() {
      // generate "current" query object, based on supplied template and params
      let newQuery = {...this.localQuery};
      return { params: Query.replaceMagic(newQuery) };
    },
  },
  props: {
    showPagination: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      required: true,
    },
    btnShow: {
      type: Boolean,
      default: true,
    },
    btnIsDd: {
      type: Boolean,
      default: false,
    },
    btnTo: {
      type: String,
      default: null,
    },
    btnClick: {
      type: Function,
      default() {},
    },
    btnVariant: {
      type: String,
      default: 'primary',
    },
    btnIdPrefix: {
      type: String,
      default: 'tab',
    },
  },
  components: {
    Pagination,
  },
}
</script>
