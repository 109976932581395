<template>
  <div v-if="!isUser">
    <b-row fluid class="mx-0 mb-2 mb-sm-3 justify-content-between">
      <h4 class="d-flex align-items-center mb-0">
        {{ $t('acc.htm') }}
      </h4>
      <b-button type="button" variant="primary" class="mx-0" id="accTabAdd" v-b-modal.accTabAddModal>
        <Icon type="add" /> {{ $t('btn.add') }}
      </b-button>
    </b-row>
    <CustTablesTable ref="table" :query="{p: ``+uiSett.visiblePerPage}" showPagination
        :btnShow="true" btnIdPrefix="tab" btnVariant="primary" :btnClick="onDelete">
      <template #buttons>
        <span class="text-nowrap"><Icon type="del" /> {{ $t('btn.del') }}</span>
      </template>
    </CustTablesTable>

    <b-modal id="accTabAddModal" ref="modal" :title="$t('acc.tadd')" size="lg" body-class="px-2 px-sm-3"
        lazy @ok="onAdd" :ok-disabled="!valAdd">
      <template #modal-cancel>
        <Icon type="a" /> {{ $t('btn.a') }}
      </template>
      <template #modal-ok>
        <Icon type="add" /> {{ $t('btn.add') }}
      </template>
      <b-form>
        <b-form-group :label="$t('acc.ltaba')" label-for="accRestCity">
          <b-input type="text" id="accTablName" v-model="tableName" :state="valAdd" @keyup.enter.native="onAdd" />
        </b-form-group>
      </b-form>
    </b-modal>
    <ModalYesNoGeneric id="accTabDelConf" :title="$t('acc.tdel')"
        :btnTextReset="$t('btn.a')" btnIconReset="a"
        :btnTextSubmit="$t('btn.del')" btnIconSubmit="del" btnVariantSubmit="secondary"
        @reset="item=null" @submit="onConfirmation(item)" />
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { REST } from '@/components/RestCall';
import ModalYesNoGeneric from '@/components/ModalYesNoGeneric';
import CustTablesTable from '@/components/account/CustTablesTable';

export default {
  data() {
    return {
      tableName: '',
      item: null,
      isAdding: false,
    }
  },
  computed: {
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    uiSett() {
      return this.$store.getters.settings;
    },
    valAdd() {
      if (this.tableName.length === 0) return null;
      return this.tableName.length <= 50;
    },
  },
  methods: {
    onDelete(item) {
      this.item = item;
      this.$bvModal.show('accTabDelConf');
    },
    onConfirmation() {
      REST.delete('/tables/' + this.item.uuid4
        ).then(resp => {
          this.$refs.table.update(true); // maybe better sync via store
          this.$store.commit('showSuccess',resp.d.message);
        }).catch(e => {
          this.$store.commit('showWarn',e.message);
        })
      this.$store.commit('clearTables');
      this.item = null;
    },
    onAdd(e) {
      e.preventDefault();
      if (this.valAdd && !this.isAdding) {
        this.isAdding = true;
        REST.post('/tables',
            { customerUuid4: this.$store.getters.uuid,
              tableName: this.tableName }
          ).then(resp => {
            this.$refs.table.update();
            this.$store.commit('showSuccess', resp.d.message);
            this.$refs.modal.hide();
          }).catch(e => {
            this.$store.commit('showWarn',e.message);
          }).finally(() => {
            this.tableName = '';
            this.isAdding = false;
          });
        this.$store.commit('clearTables');
      }
    },
  },
  components: {
    CustTablesTable,
    ModalYesNoGeneric,
    Icon,
  },
}
</script>
